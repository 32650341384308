import axios from "./../api";
import { buildQuery } from "../actions";
export default {
    namespaced: true,

    state: {
        ivip_benefits: [],
        ivip_plans: [],
        ivip_detail: {},
        ivip_prices: [],
        ivip_gifts: [],
        ivip_sponsors: [],
        ivip_orders: [],
        ivip_order_detail: {},
        ivip_claims: [],
        ivip_claim_detail: {},
        ivip_gift_variants: [],
    },

    mutations: {
        IVIP_SPONSORS(state, val) {
            state.ivip_sponsors = val;
        },
        IVIP_BENEFITS(state, val) {
            state.ivip_benefits = val;
        },

        IVIP_PLANS(state, val) {
            state.ivip_plans = val;
        },

        IVIP_PLAN_DETAIL(state, val) {
            state.ivip_plan_detail = val;
        },

        IVIP_GIFTS(state, val) {
            state.ivip_gifts = val;
        },

        IVIP_PRICES(state, val) {
            state.ivip_prices = val;
        },
        IVIP_ORDERS(state, val) {
          state.ivip_orders = val;
        },
        IVIP_ORDER_DETAIL(state, val) {
          state.ivip_order_detail = val;
        },
        IVIP_CLAIMS(state, val) {
            state.ivip_claims = val;
        },
        IVIP_CLAIM_DETAIL(state, val) {
            state.ivip_claim_detail = val;
        },
        IVIP_GIFT_VARIANTS(state, val) {
            state.ivip_gift_variants = val;
        },
    },

    getters: {
        ivip_benefits: (state) => state.ivip_benefits,
        ivip_plans: (state) => state.ivip_plans,
        ivip_plan_detail: (state) => state.ivip_plan_detail,
        ivip_gifts: (state) => state.ivip_gifts,
        ivip_prices: (state) => state.ivip_prices,
        ivip_sponsors: (state) => state.ivip_sponsors,
        ivip_orders: (state) => state.ivip_orders,
        ivip_order_detail: (state) => state.ivip_order_detail,
        ivip_claims: (state) => state.ivip_claims,
        ivip_claim_detail: (state) => state.ivip_claim_detail,
    },

    actions: {
        fetchPlans(ctx) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/v1/ivip/plans")
                .then((response) => {
                    commit("IVIP_PLANS", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchEvents: ", error);
                });
        },

        fetchBenefits(ctx) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/v1/ivip/benefits")
                .then((response) => {
                    commit("IVIP_BENEFITS", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchBenefits: ", error);
                });
        },
        fetchSponsors(ctx) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/v1/sponsors")
                .then((response) => {
                    console.log(response.data);
                    commit("IVIP_SPONSORS", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchBenefits: ", error);
                });
        },

        fetchGifts(ctx) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/v1/ivip/gifts")
                .then((response) => {
                    commit("IVIP_GIFTS", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchGifts: ", error);
                });
        },

        fetchPrices(ctx, plan_id) {
            let { commit, state } = ctx;
            axios
                .get(`/api/admin/v1/ivip/prices?plan_id=${plan_id}`)
                .then((response) => {
                    commit("IVIP_PRICES", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchPrices: ", error);
                });
        },

        planDetail(ctx, id) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/v1/ivip/plans/" + id)
                .then((response) => {
                    commit("IVIP_PLAN_DETAIL", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchEvents: ", error);
                });
        },
        giftDetail(ctx, id) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/v1/ivip/gifts/" + id)
                .then((response) => {
                    commit("IVIP_GIFTS", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchEvents: ", error);
                });
        },

        storeBenefit(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/v1/ivip/benefits`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        storePlan(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/v1/ivip/plans`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        storePrice(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/admin/v1/ivip/prices`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        updateBenefit(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/api/admin/v1/ivip/benefits/${obj.id}`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        updatePlan(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/api/admin/v1/ivip/plans/${obj.id}`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        updatePrice(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/api/admin/v1/ivip/prices/${obj.id}`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },
        updateGift(ctx, data) {
          console.log(data);
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/admin/v1/ivip/gifts/" + data.get("id"), data)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        createGift(ctx, data) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/admin/v1/ivip/gifts/", data)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        destroyBenefit(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/admin/v1/ivip/benefits/${id}`)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        destroyPlan(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/admin/v1/ivip/plans/${id}`)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        destroyPrice(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/api/admin/v1/ivip/prices/${id}`)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        /* ORDER VIP*/
        async fetchOrders(ctx, params) {
            try {
              return await axios.get('/api/admin/v1/ivip/orders' + buildQuery(params));
            } catch (error) {
              console.log(error);
            }
        },
        async OrderVipDetail(ctx, id) {
            let {commit, state} = ctx;
            axios.get('/api/admin/v1/ivip/orders/' + id)
            .then((response) => {
              commit("IVIP_ORDER_DETAIL", response.data.data);
            })
            .catch((error) => {
                console.error("fetchOrderDetail: ", error);
            });
        },
        updateOrder(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/api/admin/v1/ivip/orders/${obj.id}`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },
        async fetchClaims(ctx, params) {
            try {
              return await axios.get('/api/admin/v1/ivip/claims' + buildQuery(params));
            } catch (error) {
              console.log(error);
            }
        },
        async ClaimVipDetail(ctx, id) {
            let {commit, state} = ctx;
            axios.get('/api/admin/v1/ivip/claims/' + id)
                .then((response) => {
                    commit("IVIP_CLAIM_DETAIL", response.data.data);
                    })
                    .catch((error) => {
                        console.error("fetchClaimDetail: ", error);
                    });
        },
        updateClaim(ctx, obj) {
            return new Promise((resolve, reject) => {
                axios
                    .put(`/api/admin/v1/ivip/claims/${obj.id}`, obj)
                    .then((response) => resolve(response.data))
                    .catch((error) => reject(error));
            });
        },

        fetchVariants(ctx) {
            let { commit, state } = ctx;
            axios
                .get("/api/admin/v1/ivip/vip-variants")
                .then((response) => {
                    console.log(response.data);
                    commit("IVIP_VARIANTS", response.data.data);
                })
                .catch((error) => {
                    console.error("fetchBenefits: ", error);
                });
        },

        updateVariant(ctx, obj) {
          console.log(ctx, obj);
            return new Promise((resolve, reject) => {
              axios
                  .put(`/api/admin/v1/ivip/gift-variants/${obj.id}`, obj)
                  .then((response) => resolve(response.data))
                  .catch((error) => reject(error));
              });
        },

        storeVariant(ctx, obj) {
          console.log(ctx, obj);
            return new Promise((resolve, reject) => {
              axios
                  .post(`/api/admin/v1/ivip/gift-variants/`, obj)
                  .then((response) => resolve(response.data))
                  .catch((error) => reject(error));
              });
        },

        destroyVariant(ctx, id) {
          return new Promise((resolve, reject) => {
              axios
                  .delete(`/api/admin/v1/ivip/gift-variants/${id}`)
                  .then((response) => resolve(response.data))
                  .catch((error) => reject(error));
          });
        },

        destroyGift(ctx, id) {
          return new Promise((resolve, reject) => {
              axios
                  .delete(`/api/admin/v1/ivip/gifts/${id}`)
                  .then((response) => resolve(response.data))
                  .catch((error) => reject(error));
          });
        },
    },
};
